<template>
    <v-text-field
        v-model="valueLocal.value"
        :label="valueLocal.label"
        type="date" />
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
}
</script>
